export function cartPageScripts() {
	if ($('section').hasClass('cart')) {

		let rentalDuration = sessionStorage.getItem('rentalDuration');
		if (!rentalDuration) {
			let rentalDurationTmp = $('p.product-rental').last().html();
			rentalDuration = parseInt(lodash.split(rentalDurationTmp, ':')[1].trim(), 10);
		}

		let deliveryDate = sessionStorage.getItem('deliveryDate');
		if (!deliveryDate) {
			let deliveryDateTmp = $('p.product-delivery').last().html();
			deliveryDate = lodash.split(deliveryDateTmp, ':')[1].trim();
		}

		$('select[name="rental_duration"]').each(function () {
			$(this).val(rentalDuration);
			$(this).parent('div').parent('label').parent('div').hide();
		});

		$('input[name="delivery_date"]').each(function () {
			$(this).val(deliveryDate);
			$(this).parent('label').parent('div').hide();
			// $(this).prop('disabled', true);
		});

		$('.cart-clear').on('click', function(){
			clearCart()
		})
	}
}

function clearCart(){
	console.info('clear cart');
	$('#loader').show()
	$.ajax({
		url: ajaxurl,
		method: 'POST',
		data: {
			action: 'clear_cart_contents',
			_: new Date().getTime(), // Append timestamp to prevent caching
		},
		success: function (response) {
			window.location.reload();
		},
		error: function (data) {
			console.log('Inside Ajax Error!')
		},
	});
}