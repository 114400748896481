import collapsible from './components/collapsible';

import MicroModal from 'micromodal'
import {initModals} from './components/modal';

import { viewAnimation, smoothScroll } from "./util/animation";
import {swiperDesktop, swiperMobile} from './components/swiper';
import {hamburger, mobileHeader, stickyHeader, megaMenu} from './components/header';
import {outputMonth, countDown} from './util/dates';
import {accordion, navTabs} from './components/faqs';

import {filters} from './components/filters';
import { productPageScripts } from './util/productScripts';
import { cartPageScripts } from './util/cartScripts';
import { checkoutPageScripts } from './util/checkoutScripts';

import {inlineVideo} from './util/video';
import {phoneConcat, readMore, rolodex} from './util/text';
import {cf7Redirect} from './util/window';



(function($) {

    $(window).on('load', function () {
        
        collapsible();
        // mobileHeader();
        stickyHeader();
        hamburger();
        megaMenu();

        outputMonth();
        // phoneConcat();

        viewAnimation();
        smoothScroll();
        
        accordion();
        navTabs();
        
        swiperDesktop();
        swiperMobile();
        
        // inlineVideo();
        rolodex();
        initModals();
        
        filters();
        
        productPageScripts();
        cartPageScripts();
        checkoutPageScripts();

    });
    
    $(window).on('resize', function() {
        setTimeout(function() {
            megaMenu();
        }, 1000)
    })

})( jQuery );
