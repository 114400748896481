export function mobileHeader() {
    var headerItems = document.querySelectorAll('.menu-item-has-children') 

    if( !headerItems.length ) return;
    
    for(var i = 0; i < headerItems.length; i++) {
        headerItems[i].addEventListener('click', function(e) { 
            if(e.target.nodeName != 'A'){
                this.classList.toggle('active');
            }
         });
    }
}

export function hamburger() {
    $('.site-header__hamburger').on('click', function(){
        if($('body').hasClass('menu-open')) {
            $('body').removeClass('menu-open');
            $("html, body").removeClass('scroll-lock');
        } else {
            $('body').addClass('menu-open');
            $("html, body").addClass('scroll-lock');
        }
    });
}

export function stickyHeader() {
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });
}

export function megaMenu() {
    if ($(window).width() > 768) {
        $(document).on('mouseleave', '.site-header', function() {
            $(".site-header__megaMenu").removeClass("active");
            $(".site-header__megaMenu").hide();
            $('.megamenu-trigger').removeClass('active');
            $("html, body").removeClass('scroll-lock');
        });
        $(document).on('mouseover', '.megamenu-trigger', function() {
            $("html, body").addClass('scroll-lock');
            $(".site-header__megaMenu").removeClass("active");
            $(".site-header__megaMenu").hide();
            $('.megamenu-trigger').removeClass('active');
            $(this).addClass('active');
            $($(this).data("menu")).show();
            $($(this).data("menu")).addClass("active");
                
        });
    } else {
        $(document).on('click', '.menu-item-has-children>a', function(event) {
            // event.stopPropagation();
            event.stopImmediatePropagation();
            $(this).parent().toggleClass('active');
        });
        $(document).on('click', 'ul.sub-menu>li>a', function(event) {
            event.stopImmediatePropagation();
        });
        // Menu animation
        $('.site-header__menu').on('click', '.menu-item>a', function(event){
            $(this).addClass('menu-item-a-selected');
            setTimeout(()=>$(this).removeClass('menu-item-a-selected'),100);
        });
    }
}