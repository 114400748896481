import Swiper, { Navigation, Pagination, Autoplay, Thumbs } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);

// Modifies the options to include those set within the data-* attributes.
function getSwiperOptionAttributes(options, swiperElm) {
	// These options can be set via the attributes of the swiper element
	const setable = {
		loop: 'loop',
		spaceBetween: 'spacebetween',
		slidesPerView: 'slidesperview',
		autoplay: 'autoplay',
	}

	var optionsAttributes = swiperElm.dataset

	for (var key in setable) {
		var value = setable[key]
		if (optionsAttributes[value]) {
			options[key] = isNaN(optionsAttributes[value])
				? optionsAttributes[key]
				: parseInt(optionsAttributes[value])
		}

		// Breakpoint attributes if any
		if (optionsAttributes['768_' + value]) {
			options.breakpoints[768][key] = isNaN(
				optionsAttributes['768_' + value]
			)
				? optionsAttributes['768_' + value]
				: parseInt(optionsAttributes['768_' + value])
		}
		if (optionsAttributes['996_' + value]) {
			options.breakpoints[996][key] = isNaN(
				optionsAttributes['996_' + value]
			)
				? optionsAttributes['996_' + value]
				: parseInt(optionsAttributes['996_' + value])
		}
		if (optionsAttributes['1340_' + value]) {
			options.breakpoints[1340][key] = isNaN(
				optionsAttributes['1340_' + value]
			)
				? optionsAttributes['1340_' + value]
				: parseInt(optionsAttributes['1340_' + value])
		} else if (optionsAttributes['996_' + value]) {
			options.breakpoints[1340][key] = isNaN(
				optionsAttributes['996_' + value]
			)
				? optionsAttributes['996_' + value]
				: parseInt(optionsAttributes['996_' + value])
		}
	}

	return options
}

export function swiperDesktop() {
	// get all sliders, we need to loop them due to different settings + nav
	var swipers = document.querySelectorAll(
		'.swiper-container:not(.control):not(.mobile)'
	)
	swipers.forEach(async function (el, index) {
		var closestSection = el.closest('section')
		var controls = closestSection.querySelector('.control')
		var paginationEl = closestSection.querySelector('.swiper-pagination')
		var nextEl = closestSection.querySelector('.swiper-button-next')
		var prevEl = closestSection.querySelector('.swiper-button-prev')

		// Slider default settings
		var options = {
			speed: 600,
			loop: true,
			slidesPerView: 1,
			watchSlidesProgress: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				996: {
					slidesPerView: 3,
				},
				1340: {
					slidesPerView: 3,
					spaceBetween: 20,

				},
			},
			navigation: {
				nextEl: nextEl ? nextEl : '',
				prevEl: prevEl ? prevEl : '',
			},
			pagination: {
				el: paginationEl ? paginationEl : '',
				clickable: true,
			},
			thumbs: {},
		}

		// Slider attribute overrides
		options = getSwiperOptionAttributes(options, el)

		// Infinite Horizontal Scroll
		if (el.classList.contains('marquee-bar-swiper')) {
			options.speed = 5000;
			options.freeMode = true;
			options.freeModeMomentum = false;
			options.spaceBetween = 10;
			options.loopAddBlankSlides = true;
			options.autoplay = {
				delay: 1,
				disableOnInteraction: false,
			}
			options.breakpoints = {
				500: {
					slidesPerView: 1.5,
				},
				600: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 3,
				}
			}
		}

		// Product Slider
		if (el.classList.contains('product-slider')) {
			options.slidesPerView = 1;
			options.breakpoints = {};
			options.autoplay = false;
			options.loop = false;
		}

		// Image Gallery
		if (el.classList.contains('image-slider')) {
			options.spaceBetween = 0;
			options.slidesPerView = 3;
			options.autoplay = {
				delay: 3000,
				disableOnInteraction: false
			};
			options.breakpoints = {
				768: {
					slidesPerView: 3,
					spaceBetween: 0,
				}
			}
		}

		// Image Gallery
		if (el.classList.contains('testimonials')) {
			options.slidesPerView = 1;
			options.spaceBetween = 10;
			options.centeredSlides = true;
			options.autoplay = false;
			options.breakpoints = {
				768: {
					spaceBetween: 0
				},
				992: {
					slidesPerView: 1.3,
				}
			}
		}

		// maybe add gallery controls to the slider
		if (controls) {
			// var thumbCount = controls.querySelectorAll('.swiper-slide').length;

			var controlOptions = {
				speed: 600,
				loop: false,
				slidesPerView: 3,
				spaceBetween: 10,
				slideToClickedSlide: true,
				controller: {},
				autoplay: false,
				navigation: {
					nextEl: nextEl ? nextEl : '',
					prevEl: prevEl ? prevEl : '',
				},
			}

			controlOptions = getSwiperOptionAttributes(controlOptions, controls)

			options.thumbs.swiper = new Swiper(controls, controlOptions)

		}

		// init slider
		new Swiper(el, options)
	})
}

export function swiperMobile() {
	// mobile sliders, like logo rows etc
	// need to loop in order to get the slide count
	var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')
	mobileSwipers.forEach(function (el, index) {
		var closestSection = el.closest('section')
		var slideCount = el.querySelectorAll('.swiper-slide').length

		var options = {
			speed: 600,
			slidesPerView: 2,
			watchOverflow: true,
			loop: true,
			simulateTouch: false,
			observer: true,
			observeParents: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 4,
				},
				992: {
					slidesPerView: slideCount,
				},
			},
		}

		// init nav
		options.navigation = {
			nextEl: closestSection.querySelector('.swiper-next'),
			prevEl: closestSection.querySelector('.swiper-prev'),
		}

		options = getSwiperOptionAttributes(options, el)

		// init slider
		swiper = new Swiper(el, options)
	})
}